import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CssBaseline, Typography, useMediaQuery } from "@mui/material";
import SchoolSharpIcon from "@mui/icons-material/SchoolSharp";
import CambridgeFrame from "../../elements/CambridgeFrame";
import VideoFrame from "./VideoFrame";
import ReserveNPricingLinkFrame from "./ReserveNPricingLinkFrame";
import WorldClassFrame from "./WorldClassFrame";
import AcademicDirectorQuoteFrame from "./AcademicDirectorQuoteFrame";
import PartOfCambridgeFrame from "./PartOfCambridgeFrame";
import TransitioningFrame from "./TransitioningFrame";
import EnrollFrame from "../../elements/EnrollFrame";
import CardsFrame from "./CardsFrame";
import HeroSection from "../../elements/HeroSection";
import theme from "../../elements/Theme";
import BlogsFrame from "./BlogsFrame";
import SpecialOfferModal from "../../elements/SpecialOfferModal";

export interface IconTypographyI {
  isMobile: boolean;
  isSmallMobile: boolean;
  isShortScreen: boolean;
  text: string;
}

const IconTypography = ({
  isMobile,
  isSmallMobile,
  isShortScreen,
  text,
}: IconTypographyI) => (
  <Typography
    variant={"h3"}
    fontWeight={400}
    textAlign={"start"}
    sx={{
      display: "inline-flex",
      fontSize:
        isSmallMobile && isShortScreen ? "1rem !important" : "1.2853rem",
    }}
  >
    {!isMobile ? (
      text
    ) : (
      <>
        <SchoolSharpIcon
          fontSize="large"
          sx={{ width: "0.9em", height: "0.9em", mr: "5px", color: "#F89C27" }}
        />
        {text}
      </>
    )}
  </Typography>
);

const MainPage: React.FC = () => {
  const { t } = useTranslation();

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isSmallMobile = useMediaQuery(theme.breakpoints.down(390));
  const isShortScreen = useMediaQuery("(max-height: 760px)");

  const [offerOpen, setOfferOpen] = useState(true);

  const targetDate = new Date(Date.UTC(2025, 3, 30, 23, 59, 59));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <CssBaseline />
      <SpecialOfferModal
        open={offerOpen}
        onClose={() => setOfferOpen(false)}
        targetDate={targetDate}
      />
      <HeroSection
        mainTitle={
          <>
            {t("mainPage.mainTitle1")}
            <br />
            {t("mainPage.mainTitle2")}
          </>
        }
        secondaryTitle={
          <>
            <IconTypography
              isMobile={isMobile}
              isSmallMobile={isSmallMobile}
              isShortScreen={isShortScreen}
              text={t("mainPage.secondaryTitle1")}
            />
            <br />
            <br />
            <IconTypography
              isMobile={isMobile}
              isSmallMobile={isSmallMobile}
              isShortScreen={isShortScreen}
              text={t("mainPage.secondaryTitle2")}
            />
            <br />
            <br />
            <IconTypography
              isMobile={isMobile}
              isSmallMobile={isSmallMobile}
              isShortScreen={isShortScreen}
              text={t("mainPage.secondaryTitle3")}
            />
          </>
        }
        backgroundImageUrl={"/homePage/hero.webp"}
        altImage="Kids learing in International School Belgrade Young Sparrows"
        buttonText={t("mainPage.buttonText")}
        buttonOnClickPath={"enroll"}
        underButtonText={t("mainPage.underButtonText")}
      />
      <CambridgeFrame />
      <VideoFrame />
      <BlogsFrame />
      <ReserveNPricingLinkFrame />
      <WorldClassFrame />
      <AcademicDirectorQuoteFrame />
      <CardsFrame />
      <PartOfCambridgeFrame />
      <TransitioningFrame />
      <EnrollFrame />
    </>
  );
};

export default MainPage;
